import React from 'react';


export default function ImageAttribution ({Data}) {
console.log(Data)
console.log(Data.image_source_link.url)

const License = Data.image_license_type

console.log(License)


  return (
    <>

<a href={Data.image_source_link.url} className="text-themeBrandColor underline">{Data.image_title.text}</a> por <a href={Data.author_page_link.url} className="text-themeBrandColor underline">{Data.image_author.text}</a>

{
License === "CC BY 2.0" ? (
  <span> con licencia{` `}
    <a href="https://creativecommons.org/licenses/by/2.0/deed.es_ES" className="text-themeBrandColor underline">
CC2.0
  </a>
  </span>
) : null
}

{
License === "CC BY-SA 2.0" ? (
  <span> con licencia{` `}
    <a href="https://creativecommons.org/licenses/by-sa/2.0/deed.es" className="text-themeBrandColor underline">
    CC BY-SA 2.0
  </a>
  </span>
) : null
}

{
License === "CC BY-NC 2.0" ? (
  <span> con licencia{` `}
    <a href="https://creativecommons.org/licenses/by-nc/2.0/deed.es_ES" className="text-themeBrandColor underline">
    CC BY-NC 2.0
  </a>
  </span>
) : null
}




    </>


  )
  
}
